.SearchContainer:last-child {
  /* border: 1px solid #eee; */
  /* box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc; */
}

.SearchPrepend {
  flex: 0 0 5% !important;
}

.Toc {
  max-height: 70vh;
  /* WDS: wanna see if I can get JUST the entries in the accordion to scroll and the header stay fixed */
  /* overflow-y: auto; */
  overflow-y: auto;
  margin-top: 4px;
}

.TocEntry {
  /* border-bottom: 1px solid rgb(227, 230, 240); */
  border-left: 1px solid salmon;
  border-right: 1px solid salmon;
  border-top: 1px solid salmon;
  padding: 8px;
  padding-left: 4px;
  padding-right: 4px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.SearchListTitle {
  border-left: 1px solid salmon;
  border-right: 1px solid salmon;
  border-top: 1px solid salmon;
  border-bottom: 1px solid salmon;
  color: salmon;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.TocEntry:last-child {
  /* border-bottom: 0px none ; */
  border-bottom: 1px solid salmon;
}

.TocEntry > p {
  margin-bottom: 0 !important;
}
.TocEntryMeta {
  font-size: 0.8em;
  margin: 0;
}
.TocEntry:hover {
  background-color: rgb(227, 230, 240);
}

.TocEntryActive,
.TocEntryActive:hover {
  background-color: lightsalmon;
  color: black;
}

.SearchResultContainer {
  overflow-y: scroll !important;
}

.SearchIcon, .CancelSearchIcon {
  opacity: 1;
  color: #F35D2d;
}

.CancelSearchIcon:hover,
.CancelSearchIcon:focus {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .SearchResultContainer {
    /* max-height: 400px; */
    /* Dynamic max-height.  Love it. */
    max-height: calc(100vh - 360px);
  }

  .TocDesktop {
    display: none;
  }

  .ErrorContainer {
    width: 90%;
    margin-left: 20px;
    /* margin-right: 20px; */
  }
}

@media (min-width: 769px) {
  .SearchResultContainer {
    /* max-height: 500px; */
    max-height: calc(100vh - 280px);
  }
  .TocButtonMobile {
    display: none;
  }
}
