.SearchIcon {
  /* padding-top: 10px; */
  opacity: 1;
  height: 0.8em;
  /* width: 200px;
  height: 200px; */
}

.SearchIcon:hover,
.SearchIcon:focus {
  opacity: 0.5;
}

.SearchFilter:focus {
  outline: 0 none;
  border: 2px solid #F35D2d;
}
.SearchFilter {
  border: 2px solid #F35D2d;
  font-size: 1.1em;
  margin-top: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}
