.NavigationItems {
  margin: 0;
  margin-left: 20px;
  padding: 0;
  list-style: none;
  display: flex !important;
  flex-flow: row !important;
  align-items: left;
  height: 100%;
}

@media (max-width: 844px) {
  .NavigationItems {
    flex-flow: column !important;
    overflow-y: auto;
    height: inherit;
  }
}

.NestedNavigationItem {
  flex: 1;
  margin-left: 20px;
  color: white;
}