.ListLine:nth-child(even) {
  background-color: whitesmoke;
}

.UserList {
  /* border: 1px solid #000; */
  /* max-height: 200px; */
  max-height: 300px;
  overflow-y: scroll;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.User {
  margin-left: 4px;
}