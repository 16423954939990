.DocumentContainer {
  border: 1px solid #eee;
  margin: 20px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.Col {
  margin-bottom: 10px;
}

.PageTitle {
  margin-left: 20px;
  margin-bottom: 0;
}

.DocumentLink,
.DocumentLink:visited {
  color: #F35D2d;
}

.DocumentLink:active,
.DocumentLink:hover {
  color: lightsalmon;
}

.TrusteeContainer {
  margin-bottom: 10px;
}

.Icon {
  opacity: 1.0;
  /* width: 200px;
  height: 200px; */
  color: #F35D2d;
  /* margin-top: 10px; */
  margin-right: 10px;
}

.Icon:hover,
.Icon:focus {
  opacity: 0.5;
}

@media (min-width: 769px) {
  .MobileOnly {
    display: none;
  }
}
@media (max-width: 768px) {
  .DesktopOnly {
    display: none;
  }
}