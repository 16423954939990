.LeftColHeader {
  font-size: 1.2em;
  /* text-align: left; */
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.RightColHeader {
  font-size: 1.2em;
  text-align: left;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.About {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 48vw;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;

  font-size: 1.1em !important;
  /* border: 1px solid orange; */
}

.Overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.Overflow::before {
  position: absolute;
  content: "...";
/*   inset-block-end: 0;
  inset-inline-end: 0; */
  bottom: 0;
  right: 0;
}
.Overflow::after {
  content: "";
  position: absolute;
/*   inset-inline-end: 0; */
  right: 0;
  width: 1rem;
  height: 1rem;
  background: white;
}

.MessageBlock {
  max-height: calc(30px * 6);
  min-height: calc(30px * 6);
  /* border: 1px solid red; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}
.Snippet {
  /* max-lines: 3; */
  /* line-clamp: 3; */
  line-height: 30px;
  max-height: calc(30px * 3);
  font-size: 1.1em;
  overflow: hidden;
}

.PostToggle {
  /* background-color: greenyellow; */
  background-color: rgba(0,0,0,.03);
}
.PostToggle_active {
  background-color: lightsalmon;
}

.PostHeader {
  font-size: 1.1em;
  color: #F35D2d;
}

.PostHeaderAttributed {
  font-size: 0.9em;
}

.About {
  font-size: 1.1em;
}
/* WDS: Tried this at first but didn't like the look / feel with the box */
.LeftColumn {
  /* font-size: 1.0em; */
  margin-left: 20px;
  max-width: 48vw;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.Carousel {
  /* margin-right: 20px; */
  max-width: 48vw;
  margin-top: 20px;
}
.RightColumn {
  max-width: 48vw;
}

.TrusteeHeader {
  display: inline-block;
  text-align: left;
  width: 50%;
}
.SiteMessageLinkContainer {
  display: inline-block;
  text-align: right;
  width: 50%;
  margin-bottom: 6px;
}
.SiteMessageLink {
  color: #F35D2d;
  /* font-size: 1.1em; */
}
.SiteMessageLink:hover {
  color: lightsalmon;
}

@media (max-width: 768px) {
  .TrusteeHeader {
    width: 50%;
  }
  .SiteMessageLink {
    width: 50%;
  }
  .LeftColumn,
  .About {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 10px;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  
    max-width: 96vw;
  }
  .RightColHeader {
    margin-top: 10px;
  }
  .RightColumn,
  .Carousel {
    margin: 0;
    max-width: 100vw;
    margin-bottom: 20px;
  }
}