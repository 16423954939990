.TocLinks {
  color: red;
}

.TocLinks:hover {
  color: orange;
}

.TocLinks:visited {
  color: purple;
}

.TocEntryActive {
  background-color: lightsalmon;
  color: black;
}

.TocEntry {
  border-bottom: 1px solid rgb(227, 230, 240);
  padding: 8px;
}
.TocEntry > p {
  margin-bottom: 0 !important;
}
.TocEntryMeta {
  font-size: 0.5em;
  margin: 0;
}
.TocEntry:hover {
  background-color: rgb(227, 230, 240);
}

.TocButtonMobile {
  top:210px;
  right: 4px;
  position: fixed;
}

.Toc {
  max-height: 600px;
  /* WDS: wanna see if I can get JUST the entries in the accordion to scroll and the header stay fixed */
  /* overflow-y: auto; */
  overflow-y: unset;
  margin-top: 0px;
}

.Toc {
  /* margin-top: 4px; */
  /* WDS: Kludge to get TOC off the edge */
  /* margin-left: -8px; */
  /* margin-right: 4px; */
}

.SiteMessagesContainer {
}

.SiteMessagesCommon {
  overflow-y: scroll !important;
}

.SiteMessageContainer {
  margin-left: 0px;
}

@media (max-width: 768px) {
  .Posts {
    margin-left: 0px;
    margin-right: 0px;
  }
  .PostsContainer {
    width: 100%;
  }
  .TocDesktop {
    display: none;
  }
  .SiteMessageContainer {
    margin-left: 0px;
    max-height: calc(100vh - 162px);
  }
  .SiteMessagesCommon {
    max-height: calc(100vh - 162px);
  }
  .Toc {
    margin-left: -8px;
    margin-right: 4px;  
  }
}

@media (min-width: 769px) {
  .TocButtonMobile {
    display: none;
  }
  .MobileOnly {
    display: none;
  }
  .SiteMessagesCommon {
    max-height: calc(100vh - 230px);
  }
}
