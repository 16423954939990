.HelpIconContainer {
  display: inline-block;
  /* margin-right: 10px; */
  margin-left: 10px;
  width: 100%;
}
.HelpIconContainer > h5 {
  display: inline-block;
}
.HelpIcon {
  float: right;
  margin-right: 20px;
  font-size: 0.5em;
}
.HelpIcon:hover,
.HelpIcon:active,
.HelpIcon:focus {
  /* background: #fff; */
  background-color: transparent;
  color: rgb(97, 86, 86);
}

.HelpEntryContainer {
  margin-bottom: 10px;
  width: 100%;
}

.HelpName {
  display: inline-block;
  font-style: italic;
  width: 25%;
  vertical-align: top;
}

.HelpBody {
  display: inline-block;
  font-style: normal;
  width: 70%;
  overflow-wrap: break-word;
  vertical-align: top;
  margin-left: 10px;
}

.HelpParagraph {
  display: inline-block;
  font-style: normal;
  width: 100%;
  overflow-wrap: break-word;
  vertical-align: top;
  margin-left: 10px;
}

.HelpNote {
  display: inline-block;
  font-weight: bold;
  width: 100%;
  overflow-wrap: break-word;
  vertical-align: top;
  margin-left: 10px;
}