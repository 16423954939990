.SideDrawer {
  position: fixed;
  /* position: relative; */
  /* widows: 280px; */
  max-width: 70%; /* WDS: ??? should this have been min-width ??? */
  min-width: 70%;
  /* height: 100%; */
  /* height: calc(70vh); */
  /* left: 0px; */
  right: 0px;
  top: 0;
  z-index: 2000; /* intentionally higher than the backgdrop z-index so it's on top of it. */
  /* background-color: var(--danger); */
  /* background-image: linear-gradient(var(--danger), #EEAA7B); */
  /* padding: 32px 16px; */
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  margin-top: 5vh;
  /* margin-top: 0; */
  /* max-height: 50vh; */
}

@media (min-width: 845px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(110%);
}


.Logo {
  /* height: 11%; */
  height: 60px;
  margin-bottom: 32px;
}
