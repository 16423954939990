/* input[type=checkbox].form-check-input {
  width: 30px;
  height: 30px;
} */

/* .date-in-past {
  width: 14.3%;
  background: #ccc;
  border-right: solid 1px #fff;
 } */

.react-datepicker-wrapper {
  width: 60%;
}

/* Left-Justified this header since layout for multiple assets isn't obvious */
.rbc-row-resource > .rbc-header {
  font-size: 100% !important;
  text-align: left;
  margin-left: 20px;
}
.rbc-time-slot {
  border-top-color: lightgray !important;
}
@media(max-width: 768px) {
  .react-datepicker-wrapper {
    width: 50%;
  }  
}

/* css for changing the time slots.  used for blocking out certain times */
/* .rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
  background-image: linear-gradient(#8d5c39, #EEAA7B);
} */
.DisallowedTimeSlots {
  /* background-color: transparent !important;
  background-image: linear-gradient(#8d5c39, #EEAA7B); */
  background-color: rbg(230,230,230);
}