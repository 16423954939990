@media (min-width: 845px) {
  .NeighborsContainer {
    margin: auto;
    padding: auto;
  }
}
@media (max-width: 844px) {
  .NeighborsContainer {
    margin-left: 0px;
    padding-left: 0px;
  }
}

.NeighborList {
  margin-bottom: 10px;
}
.Heading {
  display: inline;
  margin-right: 20px;
  margin-top: 20px;
}
.SearchFilter {
  height: 32px;
  font-size: 1.1em;
  margin-top: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.CloseSearchBox {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.SearchIconContainer {
  margin-right: 0px;
  margin-top: 8px;
}
.SearchIcon {
  /* padding-top: 10px; */
  opacity: 1;
  height: 0.8em;
  /* width: 200px;
  height: 200px; */
}

.SearchIcon:hover,
.SearchIcon:focus {
  opacity: 0.5;
}


.ExcelIcon {
  /* padding-top: 10px; */
  opacity: 1;
  height: 0.8em;
  margin-top: 6px;
  /* width: 200px;
  height: 200px; */
}

.ExcelIcon:hover,
.ExcelIcon:focus {
  opacity: 0.7;
}
