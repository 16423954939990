.Title {
  margin-left: 18px;
  margin-bottom: 8px;
  font-size: 22px;
}
.Radio {
  margin-left: 10px;
  margin-top: 8px
}

.SubmitButton {
  margin-top: 8px;
}

.Published {
  font-size: 18px;
  margin-top: 8px;
}

@media(max-width: 768px) {
  .FormContainer {
    padding-top: 10px;
  }  
}