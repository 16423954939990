.Title {
  margin-left: 18px;
  margin-bottom: 8px;
  font-size: 24px;
}

.Radio {
  margin-left: 10px;
  margin-top: 8px
}

.CheckboxMainContainer {
  /* display: flex;
  flex: 1 1 auto;
  width: 1%; */
  display: block;
  width: 50%;
}
.CheckboxContainer {
  /* flex: 0 1 50%; */
  /* border: 1px solid black; */
  display: block;
}

.CheckboxLabel {
  /* margin-left: 10px; */
  margin-top: 0px;
}

.Checkbox {
  margin-left: 4px;
  /* margin-top: 8px; */
}

.SubmitButton {
  margin-top: 8px;
}

.ErrorMessage {
  font-size: 1.0em;
}

select {
  padding-left: 4px !important;
}

@media (max-width: 768px) {
  .Checkbox {
    margin-left: 0px;
  }  
}

@media (min-width: 769px) {
}