/* WDS: This is a placeholder for relayout of the header container */
.StickyTop {
  top: 0;
  position: sticky;
}

.Header {
  height: 56px !important;
  /* width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #703B09;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90; */
}

.jumbotron {
  background-image: url('../../images/IMG_9560_jumbotron.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  margin-top: 0px;
  margin-bottom: 0px !important;
  /* background-image: url("http://localhost:3004/images/sunsetHeader7_sm2.png"); */
  z-index: -2;
  opacity: 0.9;
  color: white;
  border-radius: 0% !important;
}

.Jumbotron {
  /* background-image: url('../images/IMG_9569.jpg') !important; */
  /* background-image: url('../images/IMG_9569.jpg'); */
  background-image: url('../../images/IMG_9560_jumbotron.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  margin-top: 4px;
  margin-bottom: 0px !important;
  height: 400px; /* maybe for landing page */
  opacity: 1.0;
  color: white;
}

.navbrand-nav-link {
  color: black;
}

.navbar {
  /* background-color: #E37222;
  background-color: #EEAA7B; */

  
  background-image: linear-gradient(#EEAA7B, #ff3c08);
  
  /* background-image: linear-gradient(#EEAA7B, #f5f000); */
  /* background-color: white; */
  /* color: white !important; */
}

.App-link {
  color: #09d3ac;
}

.NavLink {
  color: white;
  text-decoration: none;
  padding-left: 8px;
  padding-right: 8px;
}
.NavLink:visited {
  color: white;
  text-decoration: none;
}

.NavLink:active {
  text-decoration: none;
  color: black;
}

.NavLink:hover {
  text-decoration: none;
  color: brown;
}

.NavLinkSelected {
  color: black !important;
}