.Icon {
  opacity: 1.0;
  /* width: 200px;
  height: 200px; */
  color: #F35D2d;
  /* margin-top: 10px; */
  margin-right: 10px;
}

.Icon:hover,
.Icon:focus {
  opacity: 0.5;
}

.ErrorMessage {
  font-size: 1.0em;
}

.UploadContainer {
  display: inline-block;
  /* box-sizing: border-box;
  box-shadow: 1px 2px 3px #ccc;
  padding: 10px; */
  /* height: 40px;
  width: 40px; */
}
.UploadIconContainer {
  display: inline-block;
  /* margin-right: 10px; */
  margin-left: 10px;
}
.UploadIconContainer > h5 {
  display: inherit;
}
.FilenameText {
  display: inline-block;
  font-style: italic;
  /* word-wrap: break-word; */
  overflow-x: scroll;
  /* overflow-y: hidden; */
  max-width: 280px;
}
.ThumbnailRow {
  display: flex;
  flex-direction: row;
}
.Thumbnail {
  margin-top: 10px;
  margin-left: 10px;
  width: 130px;
  height: 130px;
  box-sizing: border-box;
  /* box-shadow: 0 2px 3px #ccc; */
}

.DeleteIcon {
  width: 20px !important;
  height: 20px;
  line-height: 35px;
  text-align: center;
  /* background: #7386D5; */
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 120px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: black;
  /* border: 1px solid black; */
}

.DeleteIcon:hover,
.DeleteIcon:active,
.DeleteIcon:focus {
  /* background: #fff; */
  background-color: transparent;
  color: rgb(97, 86, 86);
}

@media (max-width: 576px) {
  .FilenameText {
    max-width: 100px;
  }
}