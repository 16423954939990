.animated-button {
  /* Base styles for the button (optional) */
  /* margin-left: 10px; */
}

.animated-button.pulse {
  /* animation: pulse 1s ease-in-out infinite alternate; */
  /* animation: 3s linear 1s infinite alternate slidein; */
  animation: slide-text 2s ease-in-out alternate infinite;
}

@media (min-width: 769px) {
  @keyframes slide-text {
    from {
      /* box-shadow: none; */
      /* color: lightsalmon; */
      transform: translateX(80%);  
    }
    to {
      /* box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5); */
      /* box-shadow: 0 0 5px 2px salmon; */
      /* color: rgb(235, 96, 80); */
      transform: translateX(0);  
    }
  }
}

@media (max-width: 768px) {
  @keyframes slide-text {
    from {
      /* box-shadow: none; */
      /* color: lightsalmon; */
      transform: translateX(50%);  
    }
    to {
      /* box-shadow: 0 0 5px 2px rgba(0, 0, 255, 0.5); */
      /* box-shadow: 0 0 5px 2px salmon; */
      /* color: rgb(235, 96, 80); */
      transform: translateX(0);  
    }
  }
}
