.LikeIcon {
  opacity: 1;
  color: #F35D2d;
  /* height: 0.8em; */
  width: 50px !important;
  /* width: 200px;
  height: 200px; */
  display: inline-block;
  /* border: 1px solid black */

  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9em !important;
  font-weight: 300;
  line-height: 1.0em;
}

.LikeIcon:hover,
.LikeIcon:focus {
  opacity: 0.5;
}

.Like {
  margin-left: 4px;
}

.Icon {
  color: rgba(0, 0, 0, 0.75);
}
.LikesInfo {
  /* font-size: 0.8em; */
  margin-left: 4px;
  display: inline-block;

  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'MuseoModerno', cursive !important; */
  font-size: 0.9em !important;
  font-weight: 300;
  line-height: 1.0em;
}
.LikesInfo:hover,
.LikesInfo:focus {
  opacity: 0.7;
}
