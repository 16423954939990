.Radio {
  margin-left: 4px;
  margin-top: 4px
}

.YesButton {
  margin-right: 8px;
}

.CancelIt {
  margin-top: 8px;
}

.ButtonContainer {
  margin-top: 8px;
  margin-left: 8px;
}