.UserBox {
  /* display: flex; */
  align-items: center;
  /* flex-direction: column; */
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50vw;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 1.2em;
}

.SearchColumn {
  margin-left: 10px;
  padding-left: 0;
}
.Heading {
  display: inline;
  margin-right: 20px;
  margin-top: 20px;
}

.UsersContainer {
  border-top: 1px solid #F35D2d;
}

.UserRow {
  padding-top: 4px;
  padding-bottom: 4px;
}
.UserRow:first-child {
  border-top: 1px solid #F35D2d;
}
.UserRow:last-child {
  border-bottom: 1px solid #F35D2d;
}
.UserInfo {
  margin-top: 10px;
}
.Name {
  font-size: 1.4em;
}
.FormTitle {
  text-align: center;
  /* margin-bottom: 10px; */
  height: 100%;
}
.FormError {
  text-align: center;
  color: red;
  font-size: 1.2em;
}
.Form {
  width: 45vw;
  margin-left: 5%;
}
.InputGroup {
  margin-bottom: 10px;
}

.InputGroup:last-child {
  margin-bottom: 0px;
}

.Radio {
  margin-left: 10px;
  margin-top: 4px
}

.Dropdown {
  width: 200px;
}

.EditIcon {
  opacity: 1;
  color: white;
  /* width: 200px;
  height: 200px; */
}

.EditIcon:hover,
.EditIcon:focus {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .EditIcon {
    margin-right: 5px;
  }
  .UserBox {
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }

  .Form {
    width: 80vw;
  }
}
@media (min-width: 769px) {
  .EditIcon {
    margin-right: 5px;
  }
  .UserRow {
    border-left: 1px solid #F35D2d;
    border-right: 1px solid #F35D2d;
  }
  .UserRow:hover {
    background-color: lightsalmon;
  }
  .UserBox {
    margin: auto;
  }
  .AuthCol {
    text-align: center;
  }  
}