.EventScheduled {
  font-weight: bolder;
  background-color: lightsalmon;
  z-index: 200;
}

.IsToday {
  background-color: #F35D2d;
}
.IsToday:hover,
.IsToday:active,
.IsToday:focus {
  background-color: #E4A37A !important;
}

.CalendarContainer {
  width: 100%;
}

.EventRow {
  border-top: 1px solid lightsalmon;
  border-left: 1px solid lightsalmon;
  border-right: 1px solid lightsalmon;
}
.EventRow:last-child {
  border-bottom: 1px solid lightsalmon;
}

.EventRowCurrent {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.NewEventButton {
  margin-bottom: 10px;;
  margin-top: 5px;;
}

.AttendeeHeader, .EventHeader {
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: underline;
  display: inline-block;
}
.Attendee, .Group {
  font-size: 1.0em;
  /* padding: 0; */
}

.ActivityIcon {
  display: inline-block;
}

.NewCalendarEventIcon {
  margin-top: 4px;
}

.Agenda {
  display: inline-block;
  width: 95%;
  /* border: 1px solid black; */
}

.PastEvent {
  color: red;
  font-weight: bold;
}

.NewCalendarEventIcon,
.Agenda,
.CloneIcon {
  opacity: 1;
  color: #F35D2d;
  /* width: 200px;
  height: 200px; */
}

.NewCalendarEventIcon:hover,
.NewCalendarEventIcon:active,
.NewCalendarEventIcon:focus,
.Agenda:hover,
.Agenda:active,
.Agenda:focus,
.CloneIcon:hover,
.CloneIcon:active,
.CloneIcon:focus {
  opacity: 0.5;
}

.Icon {
  color: black;
  /* border: 1px solid black; */
}

.Icon:hover,
.Icon:active,
.Icon:focus {
  /* background: #fff; */
  color: gray;
}

.EventDetailContainer {
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  padding-top: 5px;
  padding-bottom: 10px;
}
.EventTitle {
  font-size: 1.3em;
  color: red;
  margin-bottom: 10px;
  display: inline-block;
  margin-left: 8px;
  width: 100%;
}
.JoinEvent {
  color: salmon;
  font-weight: bold;
}
.JoinEvent:hover,
.JoinEvent:active,
.JoinEvent:focus {
  /* background: #fff; */
  color: lightsalmon;
}

.EventDetail {
  margin-bottom: 5px;
}

.EventListContainer {
  /* overflow-y: auto; */
  flex: 1;
  margin-top: 50px;
  /* top: 400; */
  position: relative;
  /* border: 4x solid 0; */
}
.EventList {
  display: inline-block;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.EventListRow {
 /* margin-top: 10px;  */
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: 0px 0px 5px rgb(51, 51, 51); */
  box-shadow: 0px 4px 5px rgba(51, 51, 51, 0.7);
  box-sizing: border-box;
  border-top: 1px solid#515151;
  border-left: 1px solid#515151;
  border-right: 1px solid#515151;
  padding: 0px;
  margin: 0px 0px 10px;
}

.EventListRow:nth-child(even) {
  background-color: white;
}

.EventListRow:nth-child(odd) {
  background-color: white;
}
.EventListRow:hover {
  /* background-color: rgb(153, 68, 59); */
  background-color: whitesmoke;
}

.EventListDetailDate {
  /* padding-left: 18px; */
  width: 100%;
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  background-color: lightsalmon;
}

.EventListActivityIcon {
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  padding-top: 4px;
  /* background-color: lightsalmon; */
}

.EventListDetailTitle {
  /* padding-left: 18px; */
  width: 100%;
  color: salmon;
  font-size: 20px;
  /* background-color: whitesmoke; */
  /* text-align: center; */
}

@media (min-width: 769px) {
  .EventListDetailDate {
    max-width: 130px;
  }
  .EventListActivityIcon {
    padding-top: 12px;
  }
}

@media (max-width: 768px) {
  .EventList {
    display: none;
  }
  .EventDetailContainer {
    padding-bottom: 20px;
  }
  .EventListActivityIcon {
    padding-top: 12px;
  }
}