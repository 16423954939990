.Toolbar {
  height: 56px;
  width: 100%;
  /* position: fixed; */
  top: 0;
  left: 0;
  /* background-color: #703B09; */
  background-image: linear-gradient(#EEAA7B, #ff3c08);
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar nav {
  height: 100%;
}

.Logo {
  height: 80%;
}

.LogoImage {
  background-image: url('../../../images/LogoWithCircle.jpg');
  height: 54px;
  background-color: transparent;
}

.LogoName {
  font-family: cursive;
  font-weight: bold;
  color: white;
}

@media (min-width: 845px) {
  .DesktopOnly {
    margin-left: 20px;
  }
  /* Use this class if we want a hamburger button on right side of toolbar for mobile and this for hiuding full screen. */
  .ToolbarButtonToggle {
    display: none;
  }
  .toolbar__logo {
    margin: 0
  }
  .MobileTitle {
    display: none;
  }
  .LogoName {
    font-size: 1.5em;
    margin-right: 0px;
    float: left;
    /* align-items:stretch;
    flex-direction: column; */
  }
}

@media (max-width: 844px) {
  .Toolbar {
    justify-content: space-between;
  }
  .DesktopOnly {
    display: none;
  }
  .ToolbarButtonToggle {
    padding-right: 0px;
  }
  .MobileTitle {
    font-size: 1.3em;
    /* border: 1px solid black; */
    color: white;
    /* margin-left: -140px; */
    text-transform: capitalize;
  }
  .LogoName {
    font-size: 1.2em;
    margin-right: 20px;
  }  
}