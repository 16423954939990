.TocEntry {
  border-bottom: 1px solid salmon;
  padding: 8px;
  padding-left: 4px;
  padding-right: 4px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.TocEntry:first-child {
  border-top: 1px solid salmon;
}
.TocEntry > p {
  margin-bottom: 0 !important;
}
.TocEntryMeta {
  font-size: 0.9em;
  margin: 0;
}
.TocEntry:hover {
  background-color: rgb(227, 230, 240);
}

.TocEntryActive,
.TocEntryActive:hover {
  background-color: lightsalmon;
  color: black;
}

.Toc {
  /* max-height: 70vh; */
  height: 90vh;
  overflow-y: auto;
  background-color: white;
}

@media (max-width: 844px) {
  .Toc {
    /* max-height: 400px; */
    /* Dynamic max-height.  Love it. */
    /* max-height: calc(100vh - 360px); */
    max-height: 90vh;
  }
}
