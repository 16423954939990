.SideDrawer {
  position: fixed;
  /* widows: 280px; */
  max-width: 60%; /* WDS: ??? should this have been min-width ??? */
  min-width: 60%;
  /* height: 50%; */
  height: calc(70vh);
  left: 0px;
  top: 0;
  z-index: 2000; /* intentionally higher than the backgdrop z-index so it's on top of it. */
  /* background-color: var(--danger); */
  /* background-image: linear-gradient(var(--danger), #EEAA7B); */
  /* padding: 32px 16px; */
  background-image: linear-gradient(var(--danger), #EEAA7B);
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  /* margin-top: 60px; */
  overflow-y: auto;
  /* margin-top: 0; */
  /* max-height: 50vh; */
}

.CloseButtonContainer {
  position: fixed;
  top: 5px;
  right: 5px;
}

.CloseIcon {
  color: white;
  margin-right: 10px;
  font-size: 1.6em;
}

.CloseIcon:hover,
.CloseIcon:active,
.CloseIcon:focus {
  /* background: #fff; */
  /* color: transparent; */
  color: black;
}

@media (min-width: 845px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}


.Logo {
  /* height: 11%; */
  height: 60px;
  margin-bottom: 32px;
}
