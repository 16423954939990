.Title {
  /* margin-left: 18px; */
  margin-bottom: 8px;
  font-size: 24px;
  display: inline-block;
}
.Radio {
  margin-left: 10px;
  margin-top: 8px
}

.SubmitButton {
  margin-top: 8px;
}

.Published {
  font-size: 18px;
  margin-top: 8px;
}

.ModalBody {
  margin-bottom: 10px;
}

@media (max-width: 844px) {
  .Title {
    display: none;
  }
}