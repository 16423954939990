.MapContainer {
  height: 50vh;
}

.MarkerInfoContainer {
  border: 1px solid #ccc;
  /* padding: 10; */
  /* margin-top: 10px;
  margin-bottom: 10px; */
  width: 400px;
}
.MarkerAddressHeaderInfo {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4px;
  text-decoration: underline;
}
.MarkerAddressInfo {
  /* min-height: 48px; */
  font-size: 1.1em;
}
.MarkerAddressInfo:first-child {
  margin-top: 10px;
}
.MarkerAddressInfo:last-child {
  margin-bottom: 10px;
}
.MarkerAddressInfo:hover {
  background-color: lightgrey;
  display: inherit!;
}

.MapMarkerInfoDesc {
  text-align: right;
}

@media (max-width: 768px) {
  .MapContainer {
    width: 100vw;
  }
  .MarkerInfoContainer {
    width: 320px;
  }

  .MapMarkerInfoDesc {
    text-align: right;
  }
}

@media (min-width: 769px) {
  .MapContainer {
    width: 80vw;
  }
}