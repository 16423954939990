.Title {
  /* margin-left: 18px; */
  margin-bottom: 8px;
  margin-left: 10px;
  font-size: 24px;
  /* display: inline-block; */
}

.Header,
.DocHeaderumentLink:visited {
  color: lightsalmon;
  font-size: 1.1em;
  font-weight: bold;
}

.Property {
  min-height: 32px;
  font-size: 1.1em;
}

.Property:nth-child(even) {
  background-color: whitesmoke;
}

/* .Property:hover {
  background-color: lightgrey;
  display: inherit!;
} */

.PropertySelected {
  background-color: lightsalmon;
}
.PropertyHdr {
  /* background-color: #EEAA7B; */
  color: #ff3c08;
  font-size: 1.1em;
}

/* WhAddrEdit {} */
.AddrEdit:hover {
  background-color: salmon;
}
.AddrEdit:active {
  background-color: lightsalmon;
}

@media (min-width: 845px) {
  .PropertiesContainer {
    margin: 10px;
    /* padding: auto; */
  }
}
@media (max-width: 844px) {
  .PropertiesContainer {
    margin-left: 10px;
    /* padding-left: 0px; */
  }
}

.PropertyAddress {
  margin-bottom: 10px;
}
.Heading {
  display: inline;
  margin-right: 20px;
  margin-top: 20px;
}
.SearchFilter {
  height: 32px;
  font-size: 1.1em;
  margin-top: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.CloseSearchBox {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.SearchIconContainer {
  margin-right: 0px;
  margin-top: 8px;
}
.SearchIcon {
  /* padding-top: 10px; */
  opacity: 1;
  height: 0.8em;
  /* width: 200px;
  height: 200px; */
}

.SearchIcon:hover,
.SearchIcon:focus {
  opacity: 0.5;
}


.ExcelIcon {
  /* padding-top: 10px; */
  opacity: 1;
  height: 0.8em;
  margin-top: 6px;
  /* width: 200px;
  height: 200px; */
}

.ExcelIcon:hover,
.ExcelIcon:focus {
  opacity: 0.7;
}



/* .InputGroup {
  width: 130px !important;
}
 */
/* .input-group-text {
  width: 140px !important;
} */
.Radio {
  margin-left: 10px;
  margin-top: 8px
}

@media (min-width: 769px) {
  .MobileOnly {
    display: none;
  }
}
@media (max-width: 768px) {
  .DesktopOnly {
    display: none;
  }
}