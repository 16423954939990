.Title {
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 24px;
  display: inline-block;
}

.BackButton {
  /* display: inline-block; */
  float: right;
}

.Radio {
  margin-left: 5px;
  margin-top: 8px
}

.SubmitButton {
  margin-top: 8px;
}

.RadioTitle {
  font-size: 18px;
  margin-top: 5px;
}

.RadioLabelYes {
  margin-left: 10px;
  margin-top: 8px
}
.RadioLabelNo {
  margin-left: 0px;
  margin-top: 8px
}

.ImageViewerAdditional {
  max-height: 640px !important;
  height: 640px !important;
}

.DeleteIcon {
  width: 20px !important;
  height: 20px;
  line-height: 25px;
  text-align: center;
  /* background: #7386D5; */
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 100px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #F35D2d;
  /* border: 1px solid black; */
}

.DeleteIcon:hover,
.DeleteIcon:active,
.DeleteIcon:focus {
  /* background: #fff; */
  background-color: transparent;
  color: rgb(97, 86, 86);
}

.Form {
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  padding: 10px;
}