.DrawerToggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  /* width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer; */
}

.DrawerToggleButton:hover,
.DrawerToggleButton:active {
  color: gray;
}
.DrawerToggleButton div {
  width: 100%;
  height: 3px;
  background-color: white;
}

.DrawerToggleButtonText,
.DrawerToggleButtonTextInverse {
  margin-right: 10px;
}
.DrawerToggleButtonText {
  color: white;
}
.DrawerToggleButtonInverse {
  background-color: salmon;
}

/* @media (min-width: 500px) {
  .DrawerToggle {
      display: none;
  }
} */