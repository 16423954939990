.SiteMessage {
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  width: 100% !important;
  box-sizing: border-box !important;
}

.Card {
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
  padding-bottom: 4px;
  /* WDS: Shadow box a bit much with it also applied to the content and comment boxes */
  /* box-shadow: 0 2px 3px #ccc; */
  margin-bottom: 8px;
  /* margin-left: 10px; */
}

div img {
  border: 1px solid #000;
  object-fit: contain;
  height: auto;
  max-width: 100%;
  margin-bottom: 4px;
}

.DeleteIcon,
.EditIcon {
  opacity: 1;
  color: #F35D2d;
  /* width: 200px;
  height: 200px; */
}

.DeleteIcon:hover,
.DeleteIcon:focus,
.EditIcon:hover,
.EditIcon:focus {
  opacity: 0.5;
}

.DeleteIconContainer {
  display: inline-block;
  padding-left: 10px;
}

.EditButton {
  margin-top: 8px;
  background-color: #F35D2d;
}
.EditButton:hover,
.EditButton:active,
.EditButton:focus {
    background-color: lightsalmon;
}

.DeleteButton {
  margin-left: 4px;
  margin-top: 8px;
  background-color: gray !important;
  color: white;
}
.DeleteButton:hover,
.DeleteButton:active,
.DeleteButton:focus {
  background-color: lightgray !important;
}

.SiteMessageHeader {
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
  font-size: 1.1em;
  /* font-weight: bold; */
  width: 100%;
  display: block;
  box-sizing: border-box !important;
  padding-left: 8px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #F35D2d;
  background-color: #F7F7F7;
  color: white;
  color: #F35D2d;
}
.SiteMessageHeaderAttributed {
  font-size: 0.9em;
}

.SiteMessageBody {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 0;
}

.SiteMessageContainer {
  margin-top: 4px;
  /* margin-left: 6px;
  margin-right: 6px; */
  /* WDS: Removed box-shadow from around the post contents.  Seemed a bit much */
  /* box-shadow: 0 2px 3px #ccc; */
  border: 1px solid #eee;
  padding: 10px;
  /* width: 100% !important; */
  box-sizing: border-box !important;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .Card {
    margin-left: 0;
  }
  .SiteMessageContainer {
    margin-top: 4px;
    margin-left: 0px;
    margin-right: 0px;
  }  
}