.NameField {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50vw;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  border-radius: 5px;
}

.HelpIconContainer {
  display: inline-block;
  /* margin-right: 10px; */
  margin-left: 10px;
  width: 100%;
}
.HelpIconContainer > h5 {
  display: inline-block;
}

.SiteUserMessage {
  color: #F35D2d;
}

.FormTitle {
  text-align: center;
  /* margin-bottom: 10px; */
  height: 100%;
}
.FormError {
  text-align: center;
  color: red;
  font-size: 1.2em;
}
.Form {
  width: 45vw;
  margin-left: 5%;
}
.InputGroup {
  margin-bottom: 10px;
}

.RadioLabel {
  /* padding: 0; */
  /* padding-top: 4px; */
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;
  height: 37px;
}
.RadioLabelYes {
  margin-left: 10px;
  margin-top: 8px
}
.RadioLabelNo {
  margin-left: 0px;
  margin-top: 8px
}

.RadioButtons {
  display: inline-block;
  margin-left: 10px;
}
.Radio {
  margin-right: 10px;
  margin-left: 10px;
  /* padding-top: 6px; */
  margin-top: 8px;
  /* margin-bottom: 0; */
}

.InputGroup:last-child {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .NameField {
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }

  .Form {
    width: 80vw;
  }
}
@media (min-width: 769px) {
  .NameField {
    margin: auto;
  }
  .UploadCol {
    text-align: center;
  }  
}