.NestedDropdownButton {
  margin: 12px 0;
  box-sizing: border-box;
  display: block;
  color: white;
  font-size: 1.1em;
}
.NestedDropdownButton_active {
  color: black;
}
.NestedDropdownButton a,
.MainLink {
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
}

.NestedDropdownButton a:active,
.NestedDropdownButton a:focus,
.NestedDropdownButton a.active,
.MainLink_active {
  color: black;
}
.MainLink_active_up:after {
  content: " \2191";
}

.MainLink_active_dn:after {
  content: " \2193";
}

.NestedDropdownButton a:hover {
  color: brown;
}

/* for small screens up to 768px */
@media (max-width: 844px) {
  .NestedDropdownButton {
    margin-bottom: 10px;
    flex-flow: column !important;
    overflow-y: auto;
    height: 100%;
    width: auto;
    align-items: center;
  }
  .NestedDropdownButton > ul {
    padding-left: 20px;
  }
}

/* for screens > 769px */
@media (min-width: 845px) {
  .NestedDropdownButton {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex: 1;
    flex-flow: row !important;
    height: 100%;
    width: auto;
    align-items: center;
  }
  
  .NestedDropdownButton > ul {
    background-color: black;
    padding-inline-start: 0;
  }
  .NestedDropdownButton > ul > li {
    margin: 0;
    padding: 0;
  }
  .NestedDropdownButton a {
    margin-top: 30px;
    color: white;
    text-align: center;
    height: 100%;
    padding: 0px 0px; /* 0px top/bottom and 10 px left/right */
    /* border-bottom: 4px solid transparent; */
  }
  
  .NestedDropdownButton a:active,
  .NestedDropdownButton a.active {
    color: black;
  }

  .NestedDropdownButton a:hover {
    color: brown;
  }

}