.AdministrativeContainer {
  border: 1px solid #eee;
  margin: 20px;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.AdministrativeContainerHeader {
  font-size: 1.1em;
}

.ListHeader {
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
}

.ListBody {
  overflow-y: scroll;
  /* max-height: 100vh; */
}

.ListLine:nth-child(even) {
  background-color: whitesmoke;
}

.IconSimple {
  margin-right: 4px;
}

.Icon:hover {
  opacity: 0.5;
}
.Icon:nth-child(odd) {
  margin-left: 0px;
  margin-right: 4px;
}
.Icon:nth-child(even) {
  margin-left: 4px;
  /* margin-right: 10px; */
}

@media (max-width: 768px) {
  .ListHeader {
    display: none;
  }
  .ListBody {
    max-height: calc(100vh - 360px);
  }
}

@media (min-width: 769px) {
  .ListBody {
    max-height: calc(100vh - 280px);
    overflow-x: hidden;
    margin-left: 10px;
    margin-right: 10px;
  }
}