/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&family=Source+Sans+Pro:ital,wght@0,600;1,400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=MuseoModerno&family=Open+Sans:wght@400;700&display=swap'); */
html {
  font-size: 16px !important;
}

body {
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'MuseoModerno', cursive !important; */
}

p {
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'MuseoModerno', cursive !important; */
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: rgb(95, 87, 87);
  /* color: black; */
}

a,
a:hover {
  /* color: inherit; */
  color: #F35D2d;
  text-decoration: none;
  transition: all 0.3s;
}

.Placeholder {
  border: 1px solid #eee;
  margin: 20px;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.Placeholder {
  font-size: 1.3em;
  text-align: center;
}

/* .row {
  margin-left: 0px;
  margin-right: 0px;
} */

/* WDS: Taken from tutorial on how to change the arrows in a dropdown select */
.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  
  float: left;
  min-width: 200px;
  /* margin: 50px 33%; */
}

/*To remove button from IE11, thank you Matt */
/* select::-ms-expand {
     display: none;
} */
.selectdiv:after {
  content: '\2193'; /* down arrow */
  content: '\2195'; /* up/down arrow */
  font: 20px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  right: 11px;
  /*Adjust for position however you want*/
  
  top: 18px;
  padding: 0 0 2px;
  /* border-bottom: 1px solid #999; */
  /*left line */
  
  position: absolute;
  pointer-events: none;
}

th, td {
  font-size: 1.1em;
  color: black;
  margin-right: 10px;
  margin-left: 10px;
}

/* This was the only way I was able to set the default font size for the editor. */
.jodit_container:not(.jodit_inline) {
  font-size: 18px;
}
/* 
 * WDS: hiding the drag-n-drop button as the working icon doesn't show
 * and the experience is a bit confusing to users.
 */
.jodit-drag-and-drop__file-box {
  display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  
  display: block;
  width: 100%;
  /* max-width: 320px; */
  height: 50px;
  float: right;
  /* margin: 5px 0px; */
  padding: 0px 24px;
  font-size: 20px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.fileContainer, .uploadPictureContainer {
  width: 130px;
  margin: 0;
  padding: 0;
}

.fileContainer {
  box-shadow: none !important;
  text-align: right !important;
  margin: 0 !important;
  padding: 0 !important;
  align-items: baseline !important;
}

.fileContainer > input {
  width: 130px;
}
.fileContainer .chooseFileButton {
  font-size: 16px !important;
  width: 130px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.post-card {
  /* margin-top: 4px; */
}

.jumbotron {
  margin: 0 !important;
  background-image: url('../images/IMG_9560_jumbotron.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  z-index: -2;
  opacity: 0.9;
  color: white;
  transition: transform 0.3s ease-out;

  /* border-radius: 0% !important; */
}

.Open {
  transform: translateY(0);
}

.Close {
  transform: translateY(-100%);
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
  background-image: linear-gradient(#EEAA7B, #ff3c08);
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: transparent !important;
  background-image: linear-gradient(#EEAA7B, #ff3c08);
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  background-color: transparent !important;
  background-image: linear-gradient(#EEAA7B, #ff3c08) !important;
  outline: 5px auto transparent !important;
}


@media (max-height: 768px) {
  .selectdiv:after {
    top: 12px
  }
}

/* WDS: Fom test-classes.css */
/* WDS */
@media (max-width: 768px) {
  .input-group>.input-group-prepend {
    flex: 0 0 50% !important;
  }
}
@media (min-width: 769px) {
  .input-group>.input-group-prepend {
    flex: 0 0 40% !important;
  }
}
.input-group .input-group-text {
  width: 100% !important;
}
