.ThumbsUpCount {
  font-size: 1.2em;
  margin-left: 4px;
  /* margin-top: -6px; */
}
.LikeBackdrop {
  /* background-color: #F35D2d; */
}

.LikeContent {
  color: #F35D2d;
}
