.Header,
.DocHeaderumentLink:visited {
  color: #F35D2d;
}

.Header:active,
.Header:hover {
  color: lightsalmon;
}

.ModalHeader {
  color: #F35D2d;
  font-size: 1.1em;
}
.WhAddress {
  min-height: 48px;
  font-size: 0.9em;
}

/* .WhAddress:hover {
  background-color: lightgrey;
  display: inherit!;
} */
.EmailOverflow,
.EmailOverflow:active {
  text-overflow: ellipsis;
  overflow: hidden;
}
.EmailOverflow:hover {
  opacity: 0.5;
}
.EmailOverflowWide,
.EmailOverflowWide:active {
  font-weight: bold;
}
.EmailOverflowWide:hover {
  opacity: 0.5;
}

.WhUserIcon {
  margin-left: 4px;
  color: #F35D2d;
}

.SiteUserMessage {
  color: #F35D2d;
}

.WhAddressSelected {
  background-color: lightsalmon;
}
.WhAddressHdr {
  /* background-color: #EEAA7B; */
  color: #ff3c08;
  font-size: 1.1em;
}

/* WhAddrEdit {} */
.WhAddrEdit:hover {
  background-color: salmon;
}
.WhAddrEdit:active {
  background-color: lightsalmon;
}
.WhAddrDelete {
  margin-left: 4px;
}
.WhAddrDelete:hover {
  background-color: salmon;
}
.WhAddrDelete:active {
  background-color: lightsalmon;
}
/* .InputGroup {
  width: 130px !important;
}
 */
/* .input-group-text {
  width: 140px !important;
} */
.Radio {
  margin-left: 10px;
  margin-top: 8px
}

@media (max-width: 768px) {
  .EmailOverflowWide,
  .EmailOverflowWide:active {
    text-align: center;
  }
  .WhAddressSmScreen {
    display: none;
  }
}

.ContactInfo {
  border-bottom: 1px solid rgb(227, 230, 240);
}
.ContactInfo:nth-child(even) {
  background-color: lightgray;
}

@media (min-width: 769px) {
  .EmailOverflowWide,
  .EmailOverflowWide:active {
    text-align: left;
  }

  .MobileOnly {
    display: none;
  }
  .ModalDialog {
    width: 700px !important;
    max-width: 700px;
  }
}
@media (max-width: 768px) {
  .DesktopOnly {
    display: none;
  }
}