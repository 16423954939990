.TocLinks {
  color: red;
}

.TocLinks:hover {
  color: orange;
}

.TocLinks:visited {
  color: purple;
}

.TocCard {
  overflow-y: scroll !important;
}

.TocEntry {
  /* border-bottom: 1px solid rgb(227, 230, 240); */
  border-bottom: 1px solid salmon;
  padding: 8px;
  padding-left: 4px;
  padding-right: 4px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.TocEntry:last-child {
  border-bottom: 0px none ;
}

.TocEntry > p {
  margin-bottom: 0 !important;
}
.TocEntryMeta {
  font-size: 0.8em;
  margin: 0;
}
.TocEntry:hover {
  background-color: rgb(227, 230, 240);
}

.TocEntryActive,
.TocEntryActive:hover {
  background-color: lightsalmon;
  color: black;
}

.Toc {
  /* max-height: 600px; */
  overflow-y: auto;
}

@media (max-width: 768px) {
  .TocCard {
    /* max-height: 400px; */
    /* Dynamic max-height.  Love it. */
    max-height: calc(100vh - 360px);
  }
}

@media (min-width: 769px) {
  .TocCard {
    /* max-height: 500px; */
    max-height: calc(100vh - 280px);
  }
}