.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 1.1em; /* XXX: Might not be right */
}

.NavigationItem a:active,
.NavigationItem a.active {
  color: black;
}
.NavigationItem a:hover {
  color: brown;
}

/* for small screens up to 768px */
@media (max-width: 844px) {
  .NavigationItem {
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    height: 40px !important;
    width: auto;
    min-height: 40px;
    align-items: center;
  }
}

/* for screens > 769px */
@media (min-width: 845px) {
  .NavigationItem {
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }
  
  .NavigationItem a {
    color: white;
    padding: 0px 10px; /* 0px top/bottom and 10 px left/right */
    border-bottom: 4px solid transparent;
  }
  
  .NavigationItem a:active,
  .NavigationItem a.active {
    color: black;
  }

  .NavigationItem a:hover {
    color: brown;
  }

}