.CommentHdr {
  font-size: 0.8em;
}

.CommentBody {
  /* font-size: inherit; */

  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'MuseoModerno', cursive !important; */
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.1em;
  /* color: rgb(95, 87, 87); */
}

.Comment {
  box-shadow: 0 2px 3px #ccc;
  border: 1px solid #eee;
  padding: 10px;
  width: 100% !important;
  box-sizing: border-box !important;
  margin-bottom: 8px;
}

.CommentBox {
  margin-left: 10px;
  margin-right: 10px;
}

.CommentTitle {
  margin-left: 4px;
  color: #F35D2d;
}
.CommentSubmit {
  text-align: center;
  margin-top: 10px;
}

.Icon {
  opacity: 1.0;
  /* width: 200px;
  height: 200px; */
  color: #F35D2d;
  /* margin-top: 10px; */
  margin-right: 4px;
}

.Icon:hover,
.Icon:focus {
  opacity: 0.5;
}
