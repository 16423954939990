.ListContainer {
  padding-left: 10px;
}

.ListTitle {
  font-size: 1.3em;
}

.ListHeader {
  font-weight: bold;
  text-transform: capitalize;
}

.ListInfoMessage {
  font-weight: bold;
  /* text-align: center; */
}

.ListEntry {
  font-size: 1.1em;
}

.ListEntry:nth-child(even) {
  background-color: whitesmoke;
}

.Title {
  margin-left: 18px;
  margin-bottom: 8px;
  font-size: 24px;
}

.Radio {
  margin-left: 10px;
  margin-top: 8px
}

.SubmitButton {
  margin-top: 8px;
}

.FormSelect {
  margin: 0;
}
.MemberList {
  /* box-sizing: border-box; */
  border: 1px solid #ccc;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.Published {
  font-size: 18px;
  margin-top: 8px;
}

.EmailListContainer {
  margin-left: 10px;
  margin-right: 10px;
}
.ListDetailContainer {
  padding-top: 10px;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
}

.ListDetailDescriptor {
  text-align: right;
}

.MessageSelected {
  color: #F35D2d;
}
.MessageNotSelected {
  opacity: 1.0;
}

.Icon {
  display: inline-block;
  opacity: 1.0;
  /* width: 200px;
  height: 200px; */
  color: #F35D2d;
  /* margin-top: 10px; */
  /* margin-right: 10px; */
}

.Icon:hover,
.Icon:focus {
  opacity: 0.5;
}

@media(min-width: 769px) {
  .FormContainer {
    padding-top: 0px;
  }  
  .Desktop {
    display: inherit;
  }
}

@media(max-width: 768px) {
  .FormContainer {
    padding-top: 10px;
  }
  .Desktop {
    display: none;
  }
}