.GroupsContainer {
  /* align-content: center; */
  display: flex;
  margin-left: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
}
.Groups {
  width: 100%;
}

.GroupsHeader {
  /* margin: 20px; */
  font-size: 1.3em;
  width: 100%;
  text-align: center;
}

.GroupContainer {
  border: 1px solid #eee;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  font-size: 1.1em;
  height: 52px;
}

.GroupContainer:hover {
  background-color: rgb(227, 230, 240);
}

.GroupIcon {
  border: none;
  margin-top: 0px;
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
}

.GroupName {
  display: inline-block;
  font-size: 1.1em;
  margin-left: 4px;
}

.GroupDesc {
  font-size: 1.1em;
}

.GroupMemberName {
  font-size: 1.1em;
}

.PageTitle {
  margin-left: 20px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .GroupsContainer {
    margin-left: 0px;
  }
  .GroupsHeader {
    width: 100vw;
  }
  .Groups {
    margin: 0;
    width: 100vw;
  }
}