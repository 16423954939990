.btn-success {
  background-color: transparent;
  border: none;
}

.btn-success:hover,
.btn-success.hover,
.btn-success:active,
.btn-success.active,
.btn-success:focus,
.dropdown-toggle:active,
.dropdown-toggle:focus,
.dropdown-toggle:hover,
.dropdown-toggle,
.dropdown,
.show>.btn-success.dropdown-toggle {
  background-color: transparent;
  border: none;
}

.dropdown-item:active,
.dropdown-item.active {
  background-color: lightsalmon;
}

.btn-primary {
  background-color: #F35D2d !important;
  border: none !important;
  color: white !important;
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: lightsalmon !important;
  border: none !important;
}