.TocEntry {
  border-bottom: 1px solid rgb(227, 230, 240);
  padding: 8px;
}
.TocEntry > p {
  margin-bottom: 0 !important;
}
.TocEntryMeta {
  font-size: 0.5em;
  margin: 0;
}
.TocEntry:hover {
  background-color: rgb(227, 230, 240);
}

.Toc, .Photos {
  max-height: 600px;
  overflow-y: auto;
}

.Toc {
  margin-top: 4px;
}

.PhotosContainer {
  /* padding-left: 12px;
  padding-right: 12px;
  margin-right: 12px; */
  /* display: flex; */
  /* justify-content: center; */
  margin-left: 100px;
  text-align: center;
  width: 80vw;
  /* height: 90vh; */
  /* height: 600vmin; */
  /* width: 100vw; */
  /* height: 60vh; */
  /* max-height: 480px; */
  /* height: 480px; */
  max-width: 80vw;
  /* max-height: 0vh; */
  box-sizing: border-box;
}

/* .AddImageBtn::-webkit-file-upload-button {
  visibility: hidden;
}
.AddImageBtn::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.AddImageBtn:hover::before {
  border-color: black;
}
.AddImageBtn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */
.AddImageBtn {
  opacity: 0;
}
.UploaderButton {
  margin: 0;
  padding: 0;
}

.AlbumTitle {
  text-align: left;
  padding-left: 4px;
}

.AlbumTitleLike {
  font-size: 0.9em;
  display: inline-block;
  margin-left: 10px;
}

.PhotoLike {
  text-align: left; 
  margin-left: 4px;
  margin-bottom: 4px;
  font-size: 1.1em;
}

.EditIcon {
  opacity: 1;
  color: #F35D2d;
  /* width: 200px;
  height: 200px; */
}

.EditIcon:hover,
.EditIcon:focus {
  opacity: 0.5;
}

.EditButton {
  margin-top: 0px;
  background-color: #F35D2d;
}
.EditButton:hover,
.EditButton:active,
.EditButton:focus {
    background-color: lightsalmon;
}

.DeleteButton {
  margin-left: 4px;
  margin-top: 8px;
  background-color: gray !important;
  color: white;
}
.DeleteButton:hover,
.DeleteButton:active,
.DeleteButton:focus {
  background-color: lightgray !important;
}

@media (min-width: 845px) {
  .EditIcon {
    margin-right: 18px;
  }
  .AlbumsToggleButton {
    display: none;
  }
  .toolbar__logo {
    margin: 0
  }
}

@media (max-width: 844px) {
  .DeleteButton {
    margin-left: 0px;
  }
  .EditIcon {
    margin-right: 5px;
  }
  .PhotosContainer {
    margin-left: 0;
    width: 100vw;
    max-width: 100vw;
  }
  .AlbumsToggleButton {
    /* position: fixed; */
    float: right;
    /* right: 10px; */
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
  }
}
@media (min-width: 845px) {
  .AlbumsToggleButton {
    display: none;
  }
}
