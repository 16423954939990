.Content {
  margin-top: 229px;
  /* margin-top: inherit; */
}

.ContentNoMarquis {
  margin-top: 60px;
}

.Footer {
  /* float: right; */
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.Privacy {
  margin-left: 10px;
}
.CodeOfConduct {
  margin-left: 20px;
}

@media (max-width: 576px) {
  .Content {
    margin-top: 162px;
    /* margin-top: inherit; */
  }
  .ContentNoMarquis {
    margin-top: 62px;
  }
  .Footer {
    margin-top: 4px;
  }
}