.SignupBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50vw;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 3px #ccc;
  border-radius: 5px;
}

.FormTitle {
  text-align: center;
  /* margin-bottom: 10px; */
  height: 100%;
}
.FormError {
  text-align: center;
  color: red;
  font-size: 1.2em;
}
.Form {
  width: 45vw;
  margin-left: 5%;
}
.InputGroup {
  margin-bottom: 10px;
}

.InputGroup:last-child {
  margin-bottom: 0px;
}

.Dropdown {
  width: 200px;
}
@media (max-width: 768px) {
  .SignupBox {
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }

  .Form {
    width: 80vw;
  }
}
@media (min-width: 769px) {
  .SignupBox {
    margin: auto;
  }
  .AuthCol {
    text-align: center;
  }  
}